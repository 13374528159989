.contact-section
	.secondary-title
		@apply font-normal
	.contact-info
		@apply space-y-2
		li
			@apply flex text-base leading-normal text-gray-30
		p
			@apply flex-1
		a
			@apply block
		i,em
			@apply text-base leading-none text-primary w-4 flex-center mr-5
	.form-group
		input, select, textarea
			@apply placeholder:text-gray-66 placeholder-shown:bg-white
	.iframe-scale
		@apply pt-[calc(362/690*100%)]
	.btn-submit
		@apply min-w-[calc(175/1920*100rem)]
