footer
	.newsletter-block-title
		@apply space-x-7.5 flex items-center mr-10
		.icon
			@apply text-5xl leading-none text-white

	.newsletter-title
		@apply text-3xl font-bold leading-1.2 text-white uppercase
		span
			@apply font-normal text-15 block

	.newsletter-form
		@apply mr-10 w-full max-w-[calc(542/1920*100rem)] xl:mr-12.5
		.wpcf7-response-output
			@apply text-white

	.social-list
		ul
			@apply flex items-center space-x-2.5
		a
			@apply flex-center w-12.5 h-12.5 rounded-full border border-white text-white text-2xl xl:hover:bg-white xl:hover:text-primary

	.footer-address, .footer-contact, .footer-menu
		ul
			@apply space-y-3
		li
			@apply flex text-lg leading-[1.67] text-black font-normal
		em, i
			@apply text-lg text-primary leading-[1.67]
		p
			@apply flex-1 pl-4.5
		a
			@apply xl:hover:text-orange

	.footer-address
		@apply xl:max-w-[calc(560/1920*100rem)]

	.footer-title
		@apply text-xl leading-1.2 font-bold text-dark mb-4

	.footer-contact, .footer-menu
		ul
			@apply space-y-0
		p
			@apply pl-3

	.copyright
		@apply text-sm font-normal text-gray-99 leading-1.29 uppercase
