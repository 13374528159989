.career-other-item
	&+ .career-other-item
		@apply mt-3.5 pt-3.5 border-t border-gray-e1
	.career-other-title
		@apply text-lg font-bold leading-1.33 text-dark mb-2 xl:hover:text-primary
	.career-other-info
		@apply text-base leading-none text-gray-30

.career-other-title
	@apply text-26 font-bold text-dark leading-1.2 mb-7 xl:text-32

.other-positions-box
	@apply max-w-[calc(360/1920*100rem)] mx-auto rounded-2.5 bg-gray-f3 p-5 md:ml-0 xl:px-7.5 xl:py-6 xl:ml-auto xl:mr-0 xl:sticky xl:top-27 xl:z-10



.career-detail-section
	.btn-apply
		@apply min-w-[calc(240/1920*100rem)]
	.block-title
		@apply text-22 font-bold text-dark leading-1.2 mb-5
	.block-content
		@apply text-dark
		ul
			@apply list-none pl-2.5
		& + .block-content
			@apply mt-7.5
		> ul
			@apply space-y-4 pl-0
			> li
				@apply pl-9 relative
				&::before
					@apply font-awesome content-['\f058'] font-bold text-primary text-base absolute left-0 pointer-events-none
	.block-item
		@apply h-full
	.block-item-icon
		@apply flex-center w-17.5 h-17.5 mx-auto mb-3.75
		img
			@apply w-full h-full object-contain
	.block-item-title
		@apply text-center text-base font-normal leading-normal text-dark
