.swiper-relative, .swiper
	.swiper-pagination-bullet
		@apply bg-primary opacity-100 w-2.5 h-1.25 mx-1.25 rounded-none transition-all duration-200 ease-linear
		&.swiper-pagination-bullet-active
			@apply opacity-100 w-15
	.swiper-pagination
		&.swiper-pagination-white
			.swiper-pagination-bullet
				@apply bg-white

	.swiper-button
		> *
			@apply flex-center w-15 h-15 border border-gray-99 text-gray-99 bg-transparent cursor-pointer text-[calc(32/1920*100rem)] leading-none rounded-full absolute top-1/2 -translate-y-1/2 z-1 transition-all duration-200 ease-linear xl:hover:bg-primary xl:hover:border-primary xl:hover:text-white
			&.swiper-button-disabled
				@apply opacity-40
			&.swiper-button-lock
				@apply opacity-0 pointer-events-none
		&.top-45
			> *
				@apply top-[45%]
		&.top-47
			> *
				@apply top-[47%]
	.swiper-button-white
		> *
			@apply text-white border-white xl:hover:bg-primary xl:hover:border-primary xl:hover:text-white

	.swiper-scrollbar
		@apply rounded-full bg-[#E0E0E0] h-1.75 w-[82%] left-[9%]
		.swiper-scrollbar-drag
			@apply rounded-full bg-primary
