.bg-foa
	@apply bg-contain

.foa-detail-section
	.global-desc
		@apply text-gray-33 leading-1.33
		ul
			@apply list-disc pl-6.5
	.img-scale
		@apply pt-[calc(400/690*100%)] rounded-2.5

.foa-detail-1
	@apply xl:pb-15
	.foa-content
		@apply md:mt-5 md:max-w-[calc(637/1920*100rem)]

.foa-detail-2
	@apply xl:pt-15 xl:pb-20
	.foa-content
		@apply xl:mb-[calc(114/1920*100rem)]

.foa-detail-3
	@apply xl:py-15


.consulting-support-item
	box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.05)
	@apply bg-white rounded-2.5 px-3.5 pt-9 pb-10 text-center h-full
	.consulting-support-icon
		@apply flex-center h-17.5 mb-5
		img
			@apply h-full w-full object-contain
	.consulting-support-title
		@apply text-26 font-bold leading-1.2 text-gray-33 mb-1.25 xl:text-32
	.consulting-support-desc
		@apply text-lg font-normal leading-1.33 text-black mb-6.25
	.consulting-support-button
		.btn
			@apply w-full max-w-[calc(240/1920*100rem)] mt-0
