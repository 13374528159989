.heading-1-title
	@apply text-5xl leading-normal xl:text-6xl

.heading-2-title
	@apply text-4xl leading-normal xl:text-5xl

.heading-3-title
	@apply text-3xl leading-normal xl:text-4xl

.heading-4-title
	@apply text-2xl leading-normal xl:text-3xl

.heading-5-title
	@apply text-xl leading-normal xl:text-2xl

.body-1-text
	@apply text-xl leading-snug

.body-2-text
	@apply text-lg leading-snug

.body-3-text
	@apply text-base leading-snug

.body-4-text
	@apply text-sm leading-snug

.body-5-text
	@apply text-xs leading-snug
