.home-banner
	@apply relative
	.image-wrap
		@apply relative w-11/12 lg:w-[calc(936/1920*100%)]
	.img-l
		@apply w-full
	.img-s
		@apply rounded-full overflow-hidden w-[calc(475/936*100%)] absolute z-1 right-0 bottom-0 border-5 border-white scale-85 transition-all duration-500 ease-in-out xl:border-10
		.image
			@apply rounded-full
	.swiper-slide
		&.swiper-slide-active
			.img-s
				@apply scale-100 delay-100
	.banner-caption
		@apply text-dark leading-1.2 lg:absolute-center lg:z-1 lg:w-full
	.banner-title
		@apply text-5xl font-light mb-3
	.banner-sub-title
		@apply text-2xl font-bold mb-6
	.banner-desc
		@apply text-xl leading-1.2 font-normal mb-10 xl:max-w-[calc(543/1920*100rem)]
	.swiper-pagination
		@apply xl:text-left xl:w-max xl:left-[calc(1075/1920*100%)] 2xl:left-[calc(1059/1920*100%)] 2xl:bottom-5

.number-item
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06)
	@apply bg-white rounded-5 p-5 transition-all duration-200 ease-linear xl:hover:shadow-card xl:pt-7.5 xl:pb-9 xl:px-9
	.number-icon
		@apply flex-center w-12.5 h-12.5 bg-gray-f3 text-[calc(22/1920*100rem)] text-primary rounded-full mb-1.25
	.number
		@apply text-5xl font-extrabold text-primary leading-1.2 mb-2.5 flex items-center
	.number-title
		@apply text-2xl font-normal leading-1.2 text-dark

.promotion-item
	@apply relative
	.img-scale
		@apply pt-[calc(400/690*100%)]
	.promotion-caption
		@apply absolute-center inset-0 z-1 w-full h-full pointer-events-none py-10 px-5 xl:py-17.5 xl:px-10
	.promotion-title
		@apply text-xl font-bold leading-1.38 text-dark max-w-[calc(220/1920*100rem)] line-clamp-3 xl:text-26 xl:max-w-[calc(300/1920*100rem)] xl:h-27
	.promotion-button
		@apply mt-3 pointer-events-auto
	.btn
		zoom: 0.9
		@apply px-6.5
		@screen xl
			zoom: 1

.home-promotion
	@apply pb-0 xl:pb-12.5

.corporate-service-item
	@apply relative flex flex-col justify-between h-full transition-all duration-200 ease-linear
	.img-scale
		@apply pt-[calc(255/450*100%)]
	.corporate-service-caption
		@apply pt-12.5 pb-9 px-12.5 bg-white h-full flex flex-col justify-between
	.corporate-service-title
		@apply flex-center text-xl font-bold leading-1.2 text-white text-center bg-primary px-5 py-3 max-w-[calc(100%-(80/1920*100rem))] absolute top-[54%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-full z-1
	.corporate-service-desc
		@apply text-lg text-black font-normal leading-1.2 mx-auto text-center line-clamp-3 xl:max-w-[calc(355/1920*100rem)]
	.corporate-service-button
		@apply mt-3 pointer-events-auto justify-center mt-2.5
	@screen xl
		&:hover
			@apply shadow-card
			.img-scale
				img
					@apply scale-110

.home-corporate-service
	.swiper-slide
		@apply h-auto

.partner-item
	@apply relative transition-all duration-200 ease-linear rounded-2.5
	.partner-img
		@apply border border-gray-e1 flex-center p-5 bg-white h-25 xl:h-27
		img
			@apply w-full h-full object-scale-down transition-all duration-200 ease-linear
	@screen xl
		&:hover
			.partner-img
				img
					@apply scale-110

.home-partners
	.swiper
		@apply h-[calc((80*2/1920*100rem)+20px)] md:h-[calc((100*2/1920*100rem)+20px)] xl:h-[calc((110*2/1920*100rem)+20px)]
	.swiper-slide
		@apply h-20 md:h-25 xl:h-27
	.partner-item
		@apply h-full
		.partner-img
			@apply h-full
	.swiper-relative
		@apply xl:max-w-[calc(900/1920*100rem)] xl:ml-auto xl:mr-0 2xl:xl:max-w-[calc(932/1920*100rem)]
