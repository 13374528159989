@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

body {
  @apply overflow-y-overlay {} }

main {
  @apply pt-25 {} }

html, body {
  scroll-padding-top: calc(190/1920*100rem); }

.nav-fixed ul {
  @apply space-y-2.5 {} }

.nav-fixed li {
  @apply w-14 h-14 rounded-full flex-center mx-auto {} }
  .nav-fixed li.phone {
    @apply w-20 h-20 {} }
  .nav-fixed li.back-to-top {
    @apply opacity-0 pointer-events-none transition-all duration-100 ease-linear {} }
    .nav-fixed li.back-to-top.active {
      @apply opacity-100 pointer-events-auto {} }

.nav-fixed a {
  @apply flex-center w-full h-full {} }

.grecaptcha-badge {
  @apply opacity-0 pointer-events-none {} }

.button {
  @apply flex flex-wrap {} }
  .button > * {
    @apply mt-5 mr-5 last:mr-0 {} }

.link {
  @apply flex-center space-x-1.5 font-bold text-base text-black xl:text-13 xl:hover:text-primary {} }
  .link.link-gray-30 {
    @apply text-gray-30 xl:hover:text-primary {} }
  .link.link-primary {
    @apply text-primary xl:hover:text-secondary {} }

.btn {
  @apply flex-center h-12.5 px-8 space-x-2.5 rounded-full bg-primary font-bold text-base text-white border border-primary xl:text-lg xl:hover:bg-white xl:hover:text-primary {} }
  .btn.btn-solid {
    @apply text-white {} }
    .btn.btn-solid.btn-primary {
      @apply bg-primary border-primary xl:hover:bg-white xl:hover:text-primary {} }
    .btn.btn-solid.btn-purple {
      @apply bg-purple border-purple xl:hover:bg-white xl:hover:text-purple {} }
    .btn.btn-solid.btn-orange {
      @apply bg-orange border-orange xl:hover:bg-white xl:hover:text-orange {} }
    .btn.btn-solid.btn-blue {
      @apply bg-blue border-blue xl:hover:bg-white xl:hover:text-blue {} }
    .btn.btn-solid.btn-light-blue {
      @apply bg-light-blue border-light-blue xl:hover:bg-white xl:hover:text-light-blue {} }
    .btn.btn-solid.btn-green {
      @apply bg-green border-green xl:hover:bg-white xl:hover:text-green {} }
    .btn.btn-solid.btn-dark {
      @apply bg-dark border-dark xl:hover:bg-white xl:hover:text-dark {} }
  .btn.btn-lined {
    @apply bg-white {} }
    .btn.btn-lined.btn-primary {
      @apply text-primary xl:hover:bg-primary xl:hover:text-white {} }
    .btn.btn-lined.btn-purple {
      @apply text-purple xl:hover:bg-purple xl:hover:text-white {} }
    .btn.btn-lined.btn-orange {
      @apply text-orange xl:hover:bg-orange xl:hover:text-white {} }
    .btn.btn-lined.btn-blue {
      @apply text-blue xl:hover:bg-blue xl:hover:text-white {} }
    .btn.btn-lined.btn-light-blue {
      @apply text-light-blue xl:hover:bg-light-blue xl:hover:text-white {} }
    .btn.btn-lined.btn-green {
      @apply text-green xl:hover:bg-green xl:hover:text-white {} }
    .btn.btn-lined.btn-dark {
      @apply text-dark xl:hover:bg-dark xl:hover:text-white {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-primary rounded-0.5 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.hover-underline {
  @apply relative transition-all duration-300 bg-no-repeat bg-0-100 hover:bg-200-100 {} }

.global-title {
  @apply text-4xl leading-1.2 font-extrabold xl:text-5xl {} }

.secondary-title {
  @apply text-3xl leading-1.2 font-extrabold xl:text-4xl {} }

.global-desc {
  @apply text-lg leading-1.2 font-normal text-dark space-y-4.5 {} }
  .global-desc h2 {
    @apply text-26 leading-1.2 mb-6 xl:text-32 {} }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop.open {
  @apply opacity-100 pointer-events-auto {} }

.post-nav {
  @apply mt-5 xl:mt-7.5 {} }
  .post-nav .pager {
    @apply flex-center flex-wrap {} }
  .post-nav a, .post-nav span {
    @apply bg-gray-f3 w-15 h-15 flex-center transition-all duration-200 ease-linear text-xl text-gray-99 font-bold text-center rounded-full {} }
  .post-nav li {
    @apply m-2.5 {} }
    .post-nav li.active a, .post-nav li.active span, .post-nav li:hover a, .post-nav li:hover span {
      @apply text-white bg-primary {} }

.searchbox {
  @apply relative container px-0 {} }
  .searchbox input {
    @apply w-full h-12.5 border-gray-99 border rounded-1.25 pl-5 pr-12.5 text-base text-gray-33 placeholder:text-gray-99 focus:outline-none focus:border-primary {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-secondary {} }

.subscribe .wpcf7-form-control-wrap {
  @apply block {} }

.subscribe .subscribefrm {
  @apply relative {} }
  .subscribe .subscribefrm input.subscribeemail {
    @apply w-full h-12 border-gray-e1 border rounded-none pl-5 pr-12.5 text-sm text-white bg-transparent {}    @apply placeholder:text-white {}    @apply focus:outline-none focus:ring-0 {} }
  .subscribe .subscribefrm button.subscribebutton {
    @apply text-0 bg-white absolute top-1/2 -translate-y-1/2 right-1 w-10 h-10 flex-center text-primary leading-none transition-all duration-200 ease-linear xl:hover:bg-primary xl:hover:text-white {} }
    .subscribe .subscribefrm button.subscribebutton::before {
      @apply font-awesome text-lg font-black content-['\f061'] {} }

.subscribe .wpcf7-not-valid-tip {
  @apply absolute top-full left-0 w-full text-xs text-red font-normal italic {} }

.dropdown {
  @apply relative {} }

.dropdown-toggle {
  @apply whitespace-nowrap cursor-pointer {}  @apply focus:outline focus:outline-dashed focus:outline-primary {} }
  .dropdown-toggle.active i, .dropdown-toggle.active em {
    @apply last:rotate-180 {} }

.dropdown-menu {
  @apply absolute z-50 top-full translate-y-1 rounded-1 border border-black/15 py-2 min-w-[10rem] bg-white z-10 hidden mt-2 {} }
  .dropdown-menu::before {
    content: '';
    @apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent {} }
  .dropdown-menu.open {
    @apply block {} }
  .dropdown-menu.dropdown-menu-right {
    @apply right-0 {} }
  .dropdown-menu.dropdown-menu-left {
    @apply left-0 {} }
  .dropdown-menu.dropdown-menu-top {
    @apply top-0 {} }
  .dropdown-menu.dropdown-menu-bottom {
    @apply bottom-0 {} }
  .dropdown-menu a {
    @apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary {} }
  .dropdown-menu li.active {
    @apply text-primary {} }

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
[multiple],
textarea {
  @apply relative w-full bg-white border border-solid border-gray-e1 rounded-2.5 h-12.5 pl-6 pr-12.5 py-1 text-base text-gray-66 font-normal {}  @apply focus:z-1 {}  @apply placeholder:text-gray-66 placeholder-shown:bg-gray-f8 {}  @apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black {}  @apply read-only:bg-gray-e5 read-only:border-gray-cc read-only:text-black {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>");
  @apply relative w-full bg-white border border-solid border-gray-e1 rounded-2.5 h-12.5 pl-6 pr-12.5 py-1 text-base text-gray-66 font-normal {}  @apply focus:z-1 {}  @apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black {} }

textarea {
  @apply h-25 py-3 xl:h-37.5 {} }

.btn-submit,
input[type="submit"],
button[type="submit"] {
  @apply transition-all duration-200 ease-linear {} }

.form-group {
  @apply mb-5 relative space-y-2 {} }
  .form-group label {
    @apply w-full block text-lg font-bold leading-[calc(22/18)] text-gray-33 {} }
  .form-group > span, .form-group > div > span {
    @apply text-xs text-secondary italic block mt-0.5 font-primary font-normal {} }

.input-group {
  @apply relative {} }
  .input-group .input-group-icon {
    @apply absolute top-1/2 -translate-y-1/2 right-4.5 z-1 text-xl text-primary leading-none cursor-pointer m-0 {} }
  .input-group .toggle-password.active {
    @apply before:content-['\f06e'] {} }

.form-upload {
  @apply space-y-0 {} }
  .form-upload input[type='file'] {
    @apply hidden {} }
  .form-upload label {
    @apply relative w-max bg-gray-f3 border-none h-12 px-6 py-3 rounded-2.5 text-base text-gray-66 font-normal flex-center cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-e5 {} }

.form-noted {
  @apply text-base font-normal text-gray-30 {} }

.modal {
  @apply w-full max-w-[calc(1410/1920*100rem)] p-0 rounded-2.5 {} }
  .modal .modal-head {
    @apply mb-7.5 {} }
  .modal .modal-title {
    @apply font-bold text-2xl leading-1.2 text-primary md:text-3xl xl:text-4xl {} }
  .modal .modal-content {
    @apply p-7.5 xl:p-15 {} }
  @media (min-width: 1280px) {
    .modal .carousel__button {
      @apply w-11 h-11 bg-primary flex-center rounded-none rounded-tr-2.5 {} }
      .modal .carousel__button.is-close {
        @apply top-0 right-0 {} }
      .modal .carousel__button svg {
        @apply stroke-white {} } }

.apply-modal {
  @apply max-w-[calc(998/1920*100rem)] {} }
  .apply-modal .modal-content {
    @apply xl:px-7.5 xl:pt-12.5 xl:pb-15 {} }
  .apply-modal .form-group input, .apply-modal .form-group textarea {
    @apply bg-white text-gray-66 {}    @apply disabled:bg-gray-f3 disabled:border-gray-f3 {}    @apply read-only:bg-gray-f3 read-only:border-gray-f3 {} }
  .apply-modal .btn-submit {
    @apply min-w-[calc(175/1920*100rem)] {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-relative .swiper-pagination-bullet, .swiper .swiper-pagination-bullet {
  @apply bg-primary opacity-100 w-2.5 h-1.25 mx-1.25 rounded-none transition-all duration-200 ease-linear {} }
  .swiper-relative .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply opacity-100 w-15 {} }

.swiper-relative .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet, .swiper .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
  @apply bg-white {} }

.swiper-relative .swiper-button > *, .swiper .swiper-button > * {
  @apply flex-center w-15 h-15 border border-gray-99 text-gray-99 bg-transparent cursor-pointer text-[calc(32/1920*100rem)] leading-none rounded-full absolute top-1/2 -translate-y-1/2 z-1 transition-all duration-200 ease-linear xl:hover:bg-primary xl:hover:border-primary xl:hover:text-white {} }
  .swiper-relative .swiper-button > *.swiper-button-disabled, .swiper .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 {} }
  .swiper-relative .swiper-button > *.swiper-button-lock, .swiper .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper-relative .swiper-button.top-45 > *, .swiper .swiper-button.top-45 > * {
  @apply top-[45%] {} }

.swiper-relative .swiper-button.top-47 > *, .swiper .swiper-button.top-47 > * {
  @apply top-[47%] {} }

.swiper-relative .swiper-button-white > *, .swiper .swiper-button-white > * {
  @apply text-white border-white xl:hover:bg-primary xl:hover:border-primary xl:hover:text-white {} }

.swiper-relative .swiper-scrollbar, .swiper .swiper-scrollbar {
  @apply rounded-full bg-[#E0E0E0] h-1.75 w-[82%] left-[9%] {} }
  .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag, .swiper .swiper-scrollbar .swiper-scrollbar-drag {
    @apply rounded-full bg-primary {} }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.heading-1-title {
  @apply text-5xl leading-normal xl:text-6xl {} }

.heading-2-title {
  @apply text-4xl leading-normal xl:text-5xl {} }

.heading-3-title {
  @apply text-3xl leading-normal xl:text-4xl {} }

.heading-4-title {
  @apply text-2xl leading-normal xl:text-3xl {} }

.heading-5-title {
  @apply text-xl leading-normal xl:text-2xl {} }

.body-1-text {
  @apply text-xl leading-snug {} }

.body-2-text {
  @apply text-lg leading-snug {} }

.body-3-text {
  @apply text-base leading-snug {} }

.body-4-text {
  @apply text-sm leading-snug {} }

.body-5-text {
  @apply text-xs leading-snug {} }

@layer base {
  html, body {
    font-size: 3.75vw; }
  @screen md {
    html, body {
      font-size: 2vw; } }
  @screen lg {
    html, body {
      font-size: 1.618vw; } }
  @screen xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply text-main {} }
  h1 {
    @apply text-4xl font-bold {} }
  h2 {
    @apply text-3xl font-bold {} }
  h3 {
    @apply text-2xl font-bold {} }
  h4 {
    @apply text-xl font-bold {} }
  h5 {
    @apply text-lg font-bold {} }
  h6 {
    @apply text-base font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 xl:hover:text-current {} }
  img {
    @apply inline {} }
  header, main, footer, div {
    @apply xl:text-base {} } }

@layer components {
  .container {
    @apply xl:max-w-[calc(1340/1920*100rem)] 2xl:max-w-[calc(1440/1920*100rem)] {} } }

@layer utilities {
  .container-fuild {
    @apply px-3.75 {} }
  .section-py {
    @apply py-10 xl:py-12.5 {} }
  .row {
    @apply flex flex-wrap -mx-3.75 {} }
    .row > * {
      @apply px-3.75 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .swiper-webgl {
    @apply w-full h-full {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} } }

.about-1-section {
  @apply min-h-[calc(800/1920*100rem)] xl:pt-[calc(55/1920*100rem)] {} }
  .about-1-section .image {
    @apply max-w-[calc(532/1920*100rem)] px-7.5 mx-auto {} }
  .about-1-section .global-desc strong {
    @apply text-primary font-bold {} }
  .about-1-section .global-desc h2 {
    @apply text-primary font-light {} }
    .about-1-section .global-desc h2 strong {
      @apply font-extrabold {} }

.about-2-section {
  @apply xl:pt-18 {} }

.about-2-item {
  @apply flex {} }
  .about-2-item .about-2-item-icon {
    @apply w-22.5 flex-center px-4 rounded-tr-12.5 rounded-br-12.5 bg-primary min-h-[calc(250/1920*100rem)] {} }
  .about-2-item .about-2-item-caption {
    @apply flex-1 pl-7.5 {} }
  .about-2-item .about-2-item-title {
    @apply global-title text-dark mb-3.25 {} }
  .about-2-item .about-2-item-desc {
    @apply global-desc text-gray-33 {} }

.about-3-section .img-scale {
  @apply pt-[calc(1205/1920*100%)] {} }

.about-3-section .global-desc {
  @apply leading-1.33 {} }
  .about-3-section .global-desc ul {
    @apply pl-0 list-none {} }

.about-3-section .global-box {
  @apply bg-primary/30 p-5 lg:w-max xl:px-7.5 xl:py-15 {} }

.about-3-section .container {
  @apply lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:top-0 lg:w-full lg:z-1 {} }

.banner-child .img-scale {
  @apply pt-[calc(400/690*100%)] xl:pt-[calc(500/1920*100%)] {} }

.global-breadcrumb {
  @apply bg-white border-b border-white {} }
  .global-breadcrumb .rank-math-breadcrumb {
    @apply flex flex-wrap items-center py-2 {} }
    .global-breadcrumb .rank-math-breadcrumb > p {
      @apply flex items-center flex-wrap {} }
    .global-breadcrumb .rank-math-breadcrumb .separator {
      @apply text-base leading-normal font-normal text-gray-99 {} }
    .global-breadcrumb .rank-math-breadcrumb a, .global-breadcrumb .rank-math-breadcrumb span:not(.separator) {
      @apply block text-base leading-normal font-normal text-gray-99 px-4.5 xl:py-0.25 xl:hover:text-primary {} }
      .global-breadcrumb .rank-math-breadcrumb a.active, .global-breadcrumb .rank-math-breadcrumb span:not(.separator).active {
        @apply text-primary {} }
      .global-breadcrumb .rank-math-breadcrumb a:first-child, .global-breadcrumb .rank-math-breadcrumb span:not(.separator):first-child {
        @apply pl-0 {} }
      .global-breadcrumb .rank-math-breadcrumb a:last-child, .global-breadcrumb .rank-math-breadcrumb span:not(.separator):last-child {
        @apply pr-0 {} }

.career-other-item + .career-other-item {
  @apply mt-3.5 pt-3.5 border-t border-gray-e1 {} }

.career-other-item .career-other-title {
  @apply text-lg font-bold leading-1.33 text-dark mb-2 xl:hover:text-primary {} }

.career-other-item .career-other-info {
  @apply text-base leading-none text-gray-30 {} }

.career-other-title {
  @apply text-26 font-bold text-dark leading-1.2 mb-7 xl:text-32 {} }

.other-positions-box {
  @apply max-w-[calc(360/1920*100rem)] mx-auto rounded-2.5 bg-gray-f3 p-5 md:ml-0 xl:px-7.5 xl:py-6 xl:ml-auto xl:mr-0 xl:sticky xl:top-27 xl:z-10 {} }

.career-detail-section .btn-apply {
  @apply min-w-[calc(240/1920*100rem)] {} }

.career-detail-section .block-title {
  @apply text-22 font-bold text-dark leading-1.2 mb-5 {} }

.career-detail-section .block-content {
  @apply text-dark {} }
  .career-detail-section .block-content ul {
    @apply list-none pl-2.5 {} }
  .career-detail-section .block-content + .block-content {
    @apply mt-7.5 {} }
  .career-detail-section .block-content > ul {
    @apply space-y-4 pl-0 {} }
    .career-detail-section .block-content > ul > li {
      @apply pl-9 relative {} }
      .career-detail-section .block-content > ul > li::before {
        @apply font-awesome content-['\f058'] font-bold text-primary text-base absolute left-0 pointer-events-none {} }

.career-detail-section .block-item {
  @apply h-full {} }

.career-detail-section .block-item-icon {
  @apply flex-center w-17.5 h-17.5 mx-auto mb-3.75 {} }
  .career-detail-section .block-item-icon img {
    @apply w-full h-full object-contain {} }

.career-detail-section .block-item-title {
  @apply text-center text-base font-normal leading-normal text-dark {} }

.career-item {
  @apply p-2.5 rounded-2.5 bg-white h-full transition-all duration-200 ease-linear {} }
  .career-item .img-scale {
    @apply pt-[calc(248/430*100%)] rounded-2.5 {} }
  .career-item .career-caption {
    @apply pt-3.25 px-5 pb-5 {} }
  .career-item .career-title {
    @apply text-xl font-bold leading-1.2 text-dark mb-2.5 xl:hover:text-primary {} }
  .career-item .career-info {
    @apply space-y-2.5 {} }
    .career-item .career-info li {
      @apply flex items-center text-lg leading-1.33 text-dark {} }
    .career-item .career-info i, .career-item .career-info em {
      @apply mr-2 text-base text-primary w-4 flex-center {} }
  @media (min-width: 1280px) {
    .career-item:hover {
      @apply shadow-card {} }
      .career-item:hover .img-scale img {
        @apply scale-105 {} } }

.contact-section .secondary-title {
  @apply font-normal {} }

.contact-section .contact-info {
  @apply space-y-2 {} }
  .contact-section .contact-info li {
    @apply flex text-base leading-normal text-gray-30 {} }
  .contact-section .contact-info p {
    @apply flex-1 {} }
  .contact-section .contact-info a {
    @apply block {} }
  .contact-section .contact-info i, .contact-section .contact-info em {
    @apply text-base leading-none text-primary w-4 flex-center mr-5 {} }

.contact-section .form-group input, .contact-section .form-group select, .contact-section .form-group textarea {
  @apply placeholder:text-gray-66 placeholder-shown:bg-white {} }

.contact-section .iframe-scale {
  @apply pt-[calc(362/690*100%)] {} }

.contact-section .btn-submit {
  @apply min-w-[calc(175/1920*100rem)] {} }

.bg-foa {
  @apply bg-contain {} }

.foa-detail-section .global-desc {
  @apply text-gray-33 leading-1.33 {} }
  .foa-detail-section .global-desc ul {
    @apply list-disc pl-6.5 {} }

.foa-detail-section .img-scale {
  @apply pt-[calc(400/690*100%)] rounded-2.5 {} }

.foa-detail-1 {
  @apply xl:pb-15 {} }
  .foa-detail-1 .foa-content {
    @apply md:mt-5 md:max-w-[calc(637/1920*100rem)] {} }

.foa-detail-2 {
  @apply xl:pt-15 xl:pb-20 {} }
  .foa-detail-2 .foa-content {
    @apply xl:mb-[calc(114/1920*100rem)] {} }

.foa-detail-3 {
  @apply xl:py-15 {} }

.consulting-support-item {
  box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.05);
  @apply bg-white rounded-2.5 px-3.5 pt-9 pb-10 text-center h-full {} }
  .consulting-support-item .consulting-support-icon {
    @apply flex-center h-17.5 mb-5 {} }
    .consulting-support-item .consulting-support-icon img {
      @apply h-full w-full object-contain {} }
  .consulting-support-item .consulting-support-title {
    @apply text-26 font-bold leading-1.2 text-gray-33 mb-1.25 xl:text-32 {} }
  .consulting-support-item .consulting-support-desc {
    @apply text-lg font-normal leading-1.33 text-black mb-6.25 {} }
  .consulting-support-item .consulting-support-button .btn {
    @apply w-full max-w-[calc(240/1920*100rem)] mt-0 {} }

.service-wrap {
  @apply px-3.75 md:max-w-[calc(1640/1920*100rem)] md:mx-auto {} }
  .service-wrap .service-wrap-item:nth-child(2n) .row {
    @apply md:flex-row-reverse {} }

.service-wrap-item {
  @apply rounded-7.5 overflow-hidden p-10 xl:px-25 {} }
  .service-wrap-item .service-title {
    @apply global-title mb-6 xl:max-w-[calc(520/1920*100rem)] {} }
  .service-wrap-item .service-desc {
    @apply text-lg text-black font-normal leading-1.2 space-y-4 xl:max-w-[calc(600/1920*100rem)] {} }
  .service-wrap-item .service-button {
    @apply mt-5 xl:mt-7.5 {} }
    .service-wrap-item .service-button .btn {
      @apply xl:hover:bg-transparent {} }

header .logo img {
  @apply h-[calc(84/1920*100rem)] w-auto {} }

header .navbar-nav {
  @apply hidden xl:block {} }
  header .navbar-nav + .button-search {
    @apply xl:border-l xl:ml-8 {} }

header .button-search {
  @apply text-xl leading-normal text-primary px-3.5 border-r border-gray-cc cursor-pointer xl:text-base {} }

header .button-language {
  @apply ml-4 {} }
  header .button-language .dropdown-toggle {
    @apply text-xl leading-1.29 text-primary uppercase xl:text-sm {} }

header .wpml-ls-legacy-dropdown-click {
  @apply w-auto max-w-none {} }
  header .wpml-ls-legacy-dropdown-click .wpml-ls-item-toggle {
    @apply whitespace-nowrap cursor-pointer border-none text-primary pl-0 pr-5 xl:hover:text-primary xl:hover:bg-transparent {}    @apply focus:outline focus:outline-dashed focus:outline-primary {} }
    header .wpml-ls-legacy-dropdown-click .wpml-ls-item-toggle::after {
      border: none !important;
      content: '\f107' !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      @apply font-awesome {} }
  header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu::after {
    content: '';
    @apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent {} }
  header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu a {
    @apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary {} }
  header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu li.active {
    @apply text-primary {} }

header .main-menu a {
  @apply text-base leading-normal font-bold text-center uppercase {} }

@screen xl {
  header .main-menu {
    @apply flex items-center space-x-10 {} }
    header .main-menu a {
      background: linear-gradient(0deg, theme("colors.primary"), theme("colors.primary")) no-repeat right bottom/0 2px;
      transition: background-size 350ms;
      @apply relative leading-normal py-1 block hover:text-primary {} }
      header .main-menu a:hover {
        background-size: 100% 2px;
        background-position-x: left; }
    header .main-menu li.active, header .main-menu li.current-menu-item, header .main-menu li.current-services-ancestor, header .main-menu li.current-page-ancestor, header .main-menu li.current-category-ancestor, header .main-menu li.current-career-ancestor {
      @apply text-primary {} }
      header .main-menu li.active a, header .main-menu li.current-menu-item a, header .main-menu li.current-services-ancestor a, header .main-menu li.current-page-ancestor a, header .main-menu li.current-category-ancestor a, header .main-menu li.current-career-ancestor a {
        background-size: 100% 2px;
        background-position-x: left; } }

.mobile-wrap .navbar-nav {
  @apply block {} }

.mobile-wrap .main-menu {
  @apply space-y-2 {} }
  .mobile-wrap .main-menu a {
    @apply text-lg block py-2.5 text-left w-max relative hover:text-primary {} }
    .mobile-wrap .main-menu a::before {
      content: '';
      @apply absolute left-0 bottom-0 w-0 h-[2px] rounded-full bg-primary opacity-0 pointer-events-none {} }
  .mobile-wrap .main-menu li.active {
    @apply text-primary {} }
    .mobile-wrap .main-menu li.active a {
      @apply before:w-full before:opacity-100 {} }

.mobile-wrap.open {
  @apply opacity-100 pointer-events-auto {} }

.search-wrap.open {
  @apply opacity-100 pointer-events-auto {} }

.home-banner {
  @apply relative {} }
  .home-banner .image-wrap {
    @apply relative w-11/12 lg:w-[calc(936/1920*100%)] {} }
  .home-banner .img-l {
    @apply w-full {} }
  .home-banner .img-s {
    @apply rounded-full overflow-hidden w-[calc(475/936*100%)] absolute z-1 right-0 bottom-0 border-5 border-white scale-85 transition-all duration-500 ease-in-out xl:border-10 {} }
    .home-banner .img-s .image {
      @apply rounded-full {} }
  .home-banner .swiper-slide.swiper-slide-active .img-s {
    @apply scale-100 delay-100 {} }
  .home-banner .banner-caption {
    @apply text-dark leading-1.2 lg:absolute-center lg:z-1 lg:w-full {} }
  .home-banner .banner-title {
    @apply text-5xl font-light mb-3 {} }
  .home-banner .banner-sub-title {
    @apply text-2xl font-bold mb-6 {} }
  .home-banner .banner-desc {
    @apply text-xl leading-1.2 font-normal mb-10 xl:max-w-[calc(543/1920*100rem)] {} }
  .home-banner .swiper-pagination {
    @apply xl:text-left xl:w-max xl:left-[calc(1075/1920*100%)] 2xl:left-[calc(1059/1920*100%)] 2xl:bottom-5 {} }

.number-item {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  @apply bg-white rounded-5 p-5 transition-all duration-200 ease-linear xl:hover:shadow-card xl:pt-7.5 xl:pb-9 xl:px-9 {} }
  .number-item .number-icon {
    @apply flex-center w-12.5 h-12.5 bg-gray-f3 text-[calc(22/1920*100rem)] text-primary rounded-full mb-1.25 {} }
  .number-item .number {
    @apply text-5xl font-extrabold text-primary leading-1.2 mb-2.5 flex items-center {} }
  .number-item .number-title {
    @apply text-2xl font-normal leading-1.2 text-dark {} }

.promotion-item {
  @apply relative {} }
  .promotion-item .img-scale {
    @apply pt-[calc(400/690*100%)] {} }
  .promotion-item .promotion-caption {
    @apply absolute-center inset-0 z-1 w-full h-full pointer-events-none py-10 px-5 xl:py-17.5 xl:px-10 {} }
  .promotion-item .promotion-title {
    @apply text-xl font-bold leading-1.38 text-dark max-w-[calc(220/1920*100rem)] line-clamp-3 xl:text-26 xl:max-w-[calc(300/1920*100rem)] xl:h-27 {} }
  .promotion-item .promotion-button {
    @apply mt-3 pointer-events-auto {} }
  .promotion-item .btn {
    zoom: 0.9;
    @apply px-6.5 {} }

@screen xl {
  .promotion-item .btn {
    zoom: 1; } }

.home-promotion {
  @apply pb-0 xl:pb-12.5 {} }

.corporate-service-item {
  @apply relative flex flex-col justify-between h-full transition-all duration-200 ease-linear {} }
  .corporate-service-item .img-scale {
    @apply pt-[calc(255/450*100%)] {} }
  .corporate-service-item .corporate-service-caption {
    @apply pt-12.5 pb-9 px-12.5 bg-white h-full flex flex-col justify-between {} }
  .corporate-service-item .corporate-service-title {
    @apply flex-center text-xl font-bold leading-1.2 text-white text-center bg-primary px-5 py-3 max-w-[calc(100%-(80/1920*100rem))] absolute top-[54%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-full z-1 {} }
  .corporate-service-item .corporate-service-desc {
    @apply text-lg text-black font-normal leading-1.2 mx-auto text-center line-clamp-3 xl:max-w-[calc(355/1920*100rem)] {} }
  .corporate-service-item .corporate-service-button {
    @apply mt-3 pointer-events-auto justify-center mt-2.5 {} }

@screen xl {
  .corporate-service-item:hover {
    @apply shadow-card {} }
    .corporate-service-item:hover .img-scale img {
      @apply scale-110 {} } }

.home-corporate-service .swiper-slide {
  @apply h-auto {} }

.partner-item {
  @apply relative transition-all duration-200 ease-linear rounded-2.5 {} }
  .partner-item .partner-img {
    @apply border border-gray-e1 flex-center p-5 bg-white h-25 xl:h-27 {} }
    .partner-item .partner-img img {
      @apply w-full h-full object-scale-down transition-all duration-200 ease-linear {} }

@screen xl {
  .partner-item:hover .partner-img img {
    @apply scale-110 {} } }

.home-partners .swiper {
  @apply h-[calc((80*2/1920*100rem)+20px)] md:h-[calc((100*2/1920*100rem)+20px)] xl:h-[calc((110*2/1920*100rem)+20px)] {} }

.home-partners .swiper-slide {
  @apply h-20 md:h-25 xl:h-27 {} }

.home-partners .partner-item {
  @apply h-full {} }
  .home-partners .partner-item .partner-img {
    @apply h-full {} }

.home-partners .swiper-relative {
  @apply xl:max-w-[calc(900/1920*100rem)] xl:ml-auto xl:mr-0 2xl:xl:max-w-[calc(932/1920*100rem)] {} }

.full-content * {
  @apply mb-4 {} }
  .full-content *:last-child {
    @apply mb-0 {} }

.full-content ul, .full-content ol {
  list-style-type: revert;
  @apply pl-5 {} }
  .full-content ul li, .full-content ol li {
    @apply mb-2 {} }

.full-content table {
  @apply w-full {} }
  .full-content table th {
    @apply py-5 px-2.5 text-white border border-white text-center bg-primary text-base font-medium {} }
  .full-content table td {
    @apply py-5 px-2.5 text-base border border-gray-f3 text-right first:text-left xl:px-5 {} }
  .full-content table tbody tr {
    @apply even:bg-gray-f3 {} }

.full-content figure {
  @apply max-w-full {} }

.full-content a {
  @apply font-bold text-primary {} }

.news-other-title {
  @apply text-26 font-bold text-dark leading-1.2 pb-5.5 border-b border-gray-e1 mb-7.5 capitalize xl:text-32 {} }

.news-detail-section .event-news-item.event-news-horizontal-item .event-news-img {
  @apply w-[calc(180/1920*100rem)] {} }

.news-detail-section .event-news-item.event-news-horizontal-item .img-scale {
  @apply pt-[calc(102/180*100%)] {} }

.news-detail-section .event-news-item.event-news-horizontal-item .event-news-caption {
  @apply pl-3.5 {} }

.news-detail-section .event-news-item.event-news-horizontal-item .event-news-title {
  @apply text-base {} }

.news-detail-section .event-news-item.event-news-horizontal-item .event-news-desc {
  @apply text-xs h-auto {} }

.news-detail-section .tag-list a {
  @apply xl:hover:text-primary {} }

.share {
  @apply flex items-center gap-5 xl:gap-2.5 {} }
  .share .share-title {
    @apply text-base {} }
  .share a {
    @apply text-xl text-primary leading-none hover:text-secondary xl:text-lg {} }

.copy-btn {
  @apply relative {} }
  .copy-btn .tooltip {
    @apply absolute top-full left-1/2 -translate-x-1/2 bg-green text-white text-sm hidden px-1.5 py-0.5 rounded-1 z-1 mt-0.5 {} }

.event-news-item {
  @apply relative p-2.5 transition-all duration-200 ease-linear rounded-2.5 {} }
  .event-news-item .event-news-img {
    @apply relative {} }
  .event-news-item .img-scale {
    @apply pt-[calc(248.44/430*100%)] rounded-2.5 {} }
  .event-news-item .event-news-date {
    @apply absolute top-7.5 left-7.5 z-1 rounded-1.25 bg-primary p-1 text-white font-bold text-xs leading-1.2 text-center z-10 xl:w-15 xl:h-15 {} }
    .event-news-item .event-news-date .dd {
      @apply font-extrabold text-3xl block text-center {} }
    .event-news-item .event-news-date .mm-yyyy {
      @apply whitespace-nowrap {} }
  .event-news-item .event-news-caption {
    @apply pt-6.25 px-5 pb-7.5 space-y-2.5 {} }
  .event-news-item .event-news-title {
    @apply text-xl font-bold leading-1.2 text-dark xl:hover:text-primary {} }
  .event-news-item .event-news-desc {
    @apply text-lg text-dark font-normal leading-1.2 line-clamp-3 h-[calc(66/1920*100rem)] {} }
  .event-news-item .event-news-button {
    @apply mt-3 {} }

@screen xl {
  .event-news-item:hover {
    @apply bg-white {} }
    .event-news-item:hover .img-scale img {
      @apply scale-110 {} } }
  .event-news-item.event-news-big-item {
    @apply relative p-0 rounded-2.5 overflow-hidden {} }
    .event-news-item.event-news-big-item::before {
      content: '';
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 86.25%);
      @apply absolute top-0 left-0 z-1 w-full h-full pointer-events-none {} }
    .event-news-item.event-news-big-item .img-scale {
      @apply pt-[calc(468/810*100%)] {} }
    .event-news-item.event-news-big-item .event-news-date {
      @apply xl:w-22.5 xl:h-22.5 xl:p-2.5 xl:text-sm {} }
      .event-news-item.event-news-big-item .event-news-date .dd {
        @apply xl:text-[calc(43/1920*100rem)] {} }
    .event-news-item.event-news-big-item .event-news-caption {
      @apply absolute left-0 bottom-0 w-full px-7.5 py-6 z-10 {} }
    .event-news-item.event-news-big-item .event-news-title {
      @apply text-white {} }
    .event-news-item.event-news-big-item .event-news-desc {
      @apply text-white {} }
  .event-news-item.event-news-horizontal-item {
    @apply flex items-center p-0 {} }
    .event-news-item.event-news-horizontal-item .event-news-img {
      @apply w-[calc(240/1920*100rem)] {} }
    .event-news-item.event-news-horizontal-item .img-scale {
      @apply pt-[calc(136/240*100%)] {} }
    .event-news-item.event-news-horizontal-item .event-news-date {
      @apply top-2.5 left-2.5 {} }
    .event-news-item.event-news-horizontal-item .event-news-caption {
      @apply flex-1 pl-5 pr-0 py-0 {} }
    .event-news-item.event-news-horizontal-item .event-news-title {
      @apply line-clamp-2 normal-case {} }
    .event-news-item.event-news-horizontal-item .event-news-desc {
      @apply line-clamp-2 {} }

.navbar-nav-zone {
  @apply py-2.5 sticky top-24 left-0 bg-white z-996 {} }

.navbar-menu-zone {
  @apply relative flex items-center justify-center mx-auto w-max {} }
  .navbar-menu-zone::before {
    content: '';
    @apply bg-gray-e1 h-0.5 w-full rounded-full absolute left-0 bottom-0 z-1 pointer-events-none {} }
  .navbar-menu-zone a {
    @apply relative block text-lg font-normal text-gray-66 font-bold leading-none uppercase py-2.5 px-5 xl:pt-0 xl:pb-3.5 xl:px-0 xl:hover:text-primary {} }
    .navbar-menu-zone a::before {
      content: '';
      @apply bg-primary h-0.5 w-full rounded-full absolute left-0 bottom-0 z-2 pointer-events-none opacity-0 transition-all duration-200 ease-linear {} }
  .navbar-menu-zone li {
    @apply px-15 first:pl-0 last:pr-0 {} }
    .navbar-menu-zone li.active a {
      @apply text-primary before:opacity-100 {} }

@media (max-width: 575.89px) {
  .news-list-section .navbar-menu-zone {
    @apply overflow-x-auto w-full justify-start pb-5 {} }
    .news-list-section .navbar-menu-zone li {
      @apply pl-0 pr-10 last:pr-0 {} }
    .news-list-section .navbar-menu-zone a {
      @apply whitespace-nowrap px-0 {} } }

.privacy-policy-box {
  @apply xl:sticky xl:top-27 xl:z-10 {} }
  .privacy-policy-box .privacy-policy-box-title {
    @apply rounded-tl-2.5 rounded-tr-2.5 bg-primary px-7.5 py-3.75 text-white text-lg leading-1.2 capitalize font-medium {} }
  .privacy-policy-box .privacy-policy-box-menu {
    @apply rounded-bl-2.5 rounded-br-2.5 bg-gray-f3 px-7.5 py-3.75 {} }
    .privacy-policy-box .privacy-policy-box-menu a {
      @apply block text-gray-66 text-lg leading-1.2 capitalize font-medium py-4 xl:hover:text-primary xl:hover:bg-gray-f3 {} }
    .privacy-policy-box .privacy-policy-box-menu li {
      @apply border-b border-gray-e1 last:border-none {} }
      .privacy-policy-box .privacy-policy-box-menu li.active a {
        @apply text-primary {} }

.perfect-for-item {
  @apply bg-primary rounded-2.5 p-5 text-center xl:py-4 xl:px-4.5 {} }
  .perfect-for-item .perfect-for-icon {
    @apply mb-3.5 {} }
  .perfect-for-item .perfect-for-title {
    @apply text-lg text-white font-normal leading-1.2 {} }

.easy-steps-item {
  @apply relative {} }
  .easy-steps-item .easy-steps-icon {
    @apply flex-center {} }
  .easy-steps-item .easy-steps-caption {
    @apply mt-5 xl:mt-5.5 {} }
  .easy-steps-item .easy-steps-desc {
    @apply mt-7.5 text-lg leading-1.33 text-dark font-normal space-y-4 xl:mt-8.5 {} }
    .easy-steps-item .easy-steps-desc ul {
      @apply space-y-4 {} }
    .easy-steps-item .easy-steps-desc li {
      @apply pl-9.5 relative {} }
      .easy-steps-item .easy-steps-desc li::before {
        @apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 pointer-events-none {} }

.attractive-cost-box {
  @apply bg-gray-f3 rounded-2.5 p-5 max-w-[calc(690/1920*100rem)] mx-auto xl:px-7 xl:pb-7.5 {} }
  .attractive-cost-box .attractive-cost-icon {
    @apply flex-center mb-5 {} }
  .attractive-cost-box .secondary-title {
    @apply font-normal {} }
  .attractive-cost-box .btn {
    @apply xl:hover:bg-transparent {} }

.service-detail-section .img-scale {
  @apply pt-[calc(494/690*100%)] rounded-2.5 {} }

.service-detail-section .global-desc h2 {
  @apply text-xl font-normal text-primary leading-1.2 mb-4.5 xl:text-26 {} }

.service-detail-section .global-desc ul {
  @apply space-y-4 {} }

.service-detail-section .global-desc li {
  @apply pl-9.5 relative {} }
  .service-detail-section .global-desc li::before {
    @apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 top-0 pointer-events-none {} }

.service-section:nth-child(2n) {
  @apply bg-gray-f3/50 {} }
  .service-section:nth-child(2n) .container > .row {
    @apply md:flex-row-reverse {} }

.service-section:nth-child(3n) {
  @apply xl:py-15 {} }

.service-section .global-desc {
  @apply leading-1.33 {} }

.service-section .button {
  @apply mt-3.75 {} }

.service-block + .service-block {
  @apply pt-10 xl:pt-12.5 {} }

.service-block .global-desc {
  @apply leading-1.33 {} }

.service-block .btn, .service-block .link {
  @apply text-lg space-x-3 {} }

.service-sticky {
  @apply sticky top-24 left-0 bg-white z-996 {} }
  .service-sticky .navbar-nav {
    @apply relative {} }
  .service-sticky .lavalamp {
    @apply absolute bottom-0 h-0.5 bg-primary z-1 pointer-events-none transition-all duration-500 {} }
  .service-sticky .navbar-toggle {
    @apply py-2.5 px-5 text-xl font-bold text-dark shadow flex justify-between items-center xl:hidden {} }
    .service-sticky .navbar-toggle.active i, .service-sticky .navbar-toggle.active em {
      @apply rotate-180 {} }
  .service-sticky .navbar-menu {
    @apply relative py-2.5 hidden gap-5 xl:gap-7.5 xl:py-0 xl:justify-start xl:items-center 2xl:gap-10 {} }
    .service-sticky .navbar-menu::before {
      content: '';
      @apply bg-gray-e1 h-0.5 w-full rounded-full absolute left-0 bottom-0 w-full z-1 pointer-events-none hidden xl:block {} }
    .service-sticky .navbar-menu a {
      @apply block text-lg font-normal text-gray-66 font-bold leading-none uppercase py-2.5 px-5 xl:pt-0 xl:pb-3.5 xl:px-0 {} }
    .service-sticky .navbar-menu li.active a {
      @apply text-primary {} }
    @media (min-width: 1280px) {
      .service-sticky .navbar-menu {
        display: flex !important; } }

.featured-item .featured-icon {
  @apply flex-center {} }

.featured-item .featured-title {
  @apply text-xl leading-1.2 font-semibold text-gray-30 text-center px-5 py-4.5 {} }

.featured-form-box {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  @apply bg-white rounded-2.5 p-7.5 xl:-mr-7.5 xl:max-w-[calc(630/1920*100rem)] xl:ml-auto {} }
  .featured-form-box .featured-form-title {
    @apply text-2xl font-bold uppercase leading-1.2 text-dark text-center mb-4.25 xl:text-3xl {} }
  .featured-form-box .featured-form-desc {
    @apply text-lg leading-normal font-normal text-gray-30 mb-5 xl:text-base {} }
  .featured-form-box .row {
    @apply -mx-2.5 {} }
    .featured-form-box .row > * {
      @apply px-2.5 {} }
  .featured-form-box .btn-submit {
    @apply uppercase px-12.5 {} }
  .featured-form-box .form-group input, .featured-form-box .form-group select, .featured-form-box .form-group textarea {
    @apply bg-white text-base text-gray-66 px-5 {}    @apply placeholder-shown:bg-white {} }
  .featured-form-box .form-group textarea {
    @apply h-30 {} }
  .featured-form-box .form-submit {
    @apply flex-center {} }

.jurisdictions-item {
  @apply h-full {} }
  .jurisdictions-item .img-scale {
    @apply pt-[calc(260/450*100%)] rounded-2.5 {} }
  .jurisdictions-item .jurisdictions-caption {
    @apply py-5 px-4.5 xl:pt-6.5 xl:pb-4 {} }
  .jurisdictions-item .jurisdictions-title {
    @apply text-xl font-bold leading-1.2 text-black mb-2.5 xl:hover:text-primary {} }
  .jurisdictions-item .jurisdictions-desc {
    @apply text-lg leading-1.33 text-dark {} }
  @media (min-width: 1280px) {
    .jurisdictions-item:hover .img-scale img {
      @apply scale-110 {} } }

.jurisdictions-tabslet .tabslet-tabs {
  @apply flex items-center overflow-x-auto pb-3.5 mb-7.5 {} }
  .jurisdictions-tabslet .tabslet-tabs a {
    @apply block whitespace-nowrap text-2xl font-semibold leading-1.2 text-gray-99 relative hover:text-primary {} }
    .jurisdictions-tabslet .tabslet-tabs a::before {
      content: '';
      @apply absolute left-0 -bottom-3.5 w-full h-0.5 bg-transparent transition-all duration-200 ease-linear {} }
  .jurisdictions-tabslet .tabslet-tabs li {
    @apply w-max pr-10 mr-10 border-r border-gray-99 last:border-none last:mr-0 {} }
    .jurisdictions-tabslet .tabslet-tabs li.active a {
      @apply text-primary before:bg-primary {} }

.works-item {
  @apply relative {} }
  .works-item::before {
    @apply font-awesome content-['\f178'] font-normal text-primary text-50 absolute top-[4%] right-0 pointer-events-none hidden md:block xl:-right-7.5 {} }
  .works-item .works-icon {
    @apply bg-primary flex-center p-2.5 w-27 h-27 rounded-full mx-auto {} }
  .works-item .works-caption {
    @apply mt-5 xl:mt-6.5 {} }
  .works-item .works-title {
    @apply text-xl text-dark uppercase text-center uppercase font-bold leading-1.2 leading-none {} }
  .works-item .works-desc {
    @apply mt-7.5 text-lg leading-1.33 text-dark font-normal space-y-4 text-center xl:mt-8.5 {} }
    .works-item .works-desc ul {
      @apply space-y-4 text-left {} }
    .works-item .works-desc li {
      @apply pl-9.5 relative {} }
      .works-item .works-desc li::before {
        @apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 pointer-events-none {} }

.works-list > .row > *:last-child .works-item {
  @apply before:hidden {} }

.accordion-list {
  @apply space-y-3.75 {} }

.accordion-item .accordion-header {
  @apply bg-gray-f3 text-dark relative rounded-2.5 pl-5 pr-15 py-3.75 cursor-pointer xl:pr-22.5 {} }

.accordion-item .accordion-title {
  @apply text-current font-bold text-lg leading-1.33 flex {} }

.accordion-item .accordion-number {
  @apply text-26 font-bold leading-none mr-5 xl:mr-5.5 {} }

.accordion-item .accordion-icon {
  @apply absolute z-1 top-1/2 -translate-y-1/2 right-5 text-xl text-gray-99 xl:right-15 xl:cursor-pointer {} }

.accordion-item .accordion-content {
  @apply mt-3.75 bg-gray-f3 rounded-2.5 p-5 text-lg leading-normal text-dark font-normal space-y-4 hidden xl:py-6.25 xl:text-base {} }
  .accordion-item .accordion-content ul {
    @apply space-y-4 {} }
  .accordion-item .accordion-content li {
    @apply pl-9.5 relative {} }
    .accordion-item .accordion-content li::before {
      @apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 top-0.5 pointer-events-none {} }

.accordion-item.active .accordion-header {
  @apply bg-primary text-white {} }

.accordion-item.active .accordion-icon {
  @apply rotate-180 text-white {} }

.download-form-item {
  @apply bg-gray-f3 rounded-2.5 p-5 min-h-[calc(143/1920*100rem)] flex flex-col justify-between xl:py-6 xl:pb-7.5 {} }
  .download-form-item .download-form-title {
    @apply text-xl font-bold leading-1.2 text-dark mb-5 xl:hover:text-primary {} }
  .download-form-item .download-form-status {
    @apply text-lg leading-1.33 text-gray-33 font-normal {} }
    .download-form-item .download-form-status i, .download-form-item .download-form-status em {
      @apply mr-3.25 {} }
  .download-form-item .download-form-download-file {
    @apply text-lg leading-1.33 text-gray-33 font-normal xl:hover:text-primary {} }
    .download-form-item .download-form-download-file i, .download-form-item .download-form-download-file em {
      @apply mr-3.25 {} }

.service-faqs-block .accordion-item .accordion-header {
  @apply pr-10 xl:pr-15 {} }

.service-faqs-block .accordion-item .accordion-icon {
  @apply right-7.5 {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply global-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-secondary {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-gray-33 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-gray-33 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-gray-33 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

footer .newsletter-block-title {
  @apply space-x-7.5 flex items-center mr-10 {} }
  footer .newsletter-block-title .icon {
    @apply text-5xl leading-none text-white {} }

footer .newsletter-title {
  @apply text-3xl font-bold leading-1.2 text-white uppercase {} }
  footer .newsletter-title span {
    @apply font-normal text-15 block {} }

footer .newsletter-form {
  @apply mr-10 w-full max-w-[calc(542/1920*100rem)] xl:mr-12.5 {} }
  footer .newsletter-form .wpcf7-response-output {
    @apply text-white {} }

footer .social-list ul {
  @apply flex items-center space-x-2.5 {} }

footer .social-list a {
  @apply flex-center w-12.5 h-12.5 rounded-full border border-white text-white text-2xl xl:hover:bg-white xl:hover:text-primary {} }

footer .footer-address ul, footer .footer-contact ul, footer .footer-menu ul {
  @apply space-y-3 {} }

footer .footer-address li, footer .footer-contact li, footer .footer-menu li {
  @apply flex text-lg leading-[1.67] text-black font-normal {} }

footer .footer-address em, footer .footer-address i, footer .footer-contact em, footer .footer-contact i, footer .footer-menu em, footer .footer-menu i {
  @apply text-lg text-primary leading-[1.67] {} }

footer .footer-address p, footer .footer-contact p, footer .footer-menu p {
  @apply flex-1 pl-4.5 {} }

footer .footer-address a, footer .footer-contact a, footer .footer-menu a {
  @apply xl:hover:text-orange {} }

footer .footer-address {
  @apply xl:max-w-[calc(560/1920*100rem)] {} }

footer .footer-title {
  @apply text-xl leading-1.2 font-bold text-dark mb-4 {} }

footer .footer-contact ul, footer .footer-menu ul {
  @apply space-y-0 {} }

footer .footer-contact p, footer .footer-menu p {
  @apply pl-3 {} }

footer .copyright {
  @apply text-sm font-normal text-gray-99 leading-1.29 uppercase {} }
