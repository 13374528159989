header
	.logo
		img
			@apply h-[calc(84/1920*100rem)] w-auto
	.navbar-nav
		@apply hidden xl:block
		& + .button-search
			@apply xl:border-l xl:ml-8

	.button-search
		@apply text-xl leading-normal text-primary px-3.5 border-r border-gray-cc cursor-pointer xl:text-base

	.button-language
		@apply ml-4
		.dropdown-toggle
			@apply text-xl leading-1.29 text-primary uppercase xl:text-sm

	.wpml-ls-legacy-dropdown-click
		@apply w-auto max-w-none
		.wpml-ls-item-toggle
			@apply whitespace-nowrap cursor-pointer border-none text-primary pl-0 pr-5 xl:hover:text-primary xl:hover:bg-transparent
			@apply focus:outline focus:outline-dashed focus:outline-primary
			&::after
				border: none !important
				content: '\f107' !important
				top: 50% !important
				transform: translateY(-50%) !important
				@apply font-awesome
		.wpml-ls-sub-menu
			&::after
				content: ''
				@apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent
			a
				@apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary
			li
				&.active
					@apply text-primary

	.main-menu
		a
			@apply text-base leading-normal font-bold text-center uppercase
		@screen xl
			@apply flex items-center space-x-10
			a
				background: linear-gradient(0deg, theme('colors.primary'), theme('colors.primary')) no-repeat right bottom / 0 2px
				transition: background-size 350ms
				@apply relative leading-normal py-1 block hover:text-primary
				&:hover
					background-size: 100% 2px
					background-position-x: left
			li
				&.active, &.current-menu-item, &.current-services-ancestor, &.current-page-ancestor, &.current-category-ancestor, &.current-career-ancestor
					@apply text-primary
					a
						background-size: 100% 2px
						background-position-x: left

.mobile-wrap
	.navbar-nav
		@apply block
	.main-menu
		@apply space-y-2
		a
			@apply text-lg block py-2.5 text-left w-max relative hover:text-primary
			&::before
				content: ''
				@apply absolute left-0 bottom-0 w-0 h-[2px] rounded-full bg-primary opacity-0 pointer-events-none
		li
			&.active
				@apply text-primary
				a
					@apply before:w-full before:opacity-100
	&.open
		@apply opacity-100 pointer-events-auto

.search-wrap
	&.open
		@apply opacity-100 pointer-events-auto
