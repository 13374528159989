.career-item
	@apply p-2.5 rounded-2.5 bg-white h-full transition-all duration-200 ease-linear
	.img-scale
		@apply pt-[calc(248/430*100%)] rounded-2.5
	.career-caption
		@apply pt-3.25 px-5 pb-5
	.career-title
		@apply text-xl font-bold leading-1.2 text-dark mb-2.5 xl:hover:text-primary
	.career-info
		@apply space-y-2.5
		li
			@apply flex items-center text-lg leading-1.33 text-dark
		i,em
			@apply mr-2 text-base text-primary w-4 flex-center
	@media(min-width: 1280px)
		&:hover
			@apply shadow-card
			.img-scale
				img
					@apply scale-105
