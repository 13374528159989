@import 'src/components/_core/_mixins'

.edit-link i:before
	content: '✏️'

body
	@apply overflow-y-overlay

main
	@apply pt-25

html, body
	scroll-padding-top: calc(190/1920*100rem)

.nav-fixed
	ul
		@apply space-y-2.5
	li
		@apply w-14 h-14 rounded-full flex-center mx-auto
		&.phone
			@apply w-20 h-20
		&.back-to-top
			@apply opacity-0 pointer-events-none transition-all duration-100 ease-linear
			&.active
				@apply opacity-100 pointer-events-auto
	a
		@apply flex-center w-full h-full

.grecaptcha-badge
	@apply opacity-0 pointer-events-none
