.full-content
	*
		@apply mb-4
		&:last-child
			@apply mb-0
	ul, ol
		list-style-type: revert
		@apply pl-5
		li
			@apply mb-2
	table
		@apply w-full
		th
			@apply py-5 px-2.5 text-white border border-white text-center bg-primary text-base font-medium
		td
			@apply py-5 px-2.5 text-base border border-gray-f3 text-right first:text-left xl:px-5
		tbody
			tr
				@apply even:bg-gray-f3
	figure
		@apply max-w-full
	a
		@apply font-bold text-primary

.news-other-title
	@apply text-26 font-bold text-dark leading-1.2 pb-5.5 border-b border-gray-e1 mb-7.5 capitalize xl:text-32

.news-detail-section
	.event-news-item.event-news-horizontal-item
		.event-news-img
			@apply w-[calc(180/1920*100rem)]
		.img-scale
			@apply pt-[calc(102/180*100%)]
		.event-news-caption
			@apply pl-3.5
		.event-news-title
			@apply text-base
		.event-news-desc
			@apply text-xs h-auto
	.tag-list
		a
			@apply xl:hover:text-primary

.share
	@apply flex items-center gap-5 xl:gap-2.5
	.share-title
		@apply text-base
	a
		@apply text-xl text-primary leading-none hover:text-secondary xl:text-lg

.copy-btn
	@apply relative
	.tooltip
		@apply absolute top-full left-1/2 -translate-x-1/2 bg-green text-white text-sm hidden px-1.5 py-0.5 rounded-1 z-1 mt-0.5
