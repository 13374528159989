.dropdown
	@apply relative

.dropdown-toggle
	@apply whitespace-nowrap cursor-pointer
	@apply focus:outline focus:outline-dashed focus:outline-primary
	&.active
		i, em
			@apply last:rotate-180

.dropdown-menu
	@apply absolute z-50 top-full translate-y-1 rounded-1 border border-black/15 py-2 min-w-[10rem] bg-white z-10 hidden mt-2
	&::before
		content: ''
		@apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent
	&.open
		@apply block
	&.dropdown-menu-right
		@apply right-0
	&.dropdown-menu-left
		@apply left-0
	&.dropdown-menu-top
		@apply top-0
	&.dropdown-menu-bottom
		@apply bottom-0
	a
		@apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary
	li
		&.active
			@apply text-primary
