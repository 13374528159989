.perfect-for-item
	@apply bg-primary rounded-2.5 p-5 text-center xl:py-4 xl:px-4.5
	.perfect-for-icon
		@apply mb-3.5
	.perfect-for-title
		@apply text-lg text-white font-normal leading-1.2

.easy-steps-item
	@apply relative
	.easy-steps-icon
		@apply flex-center
	.easy-steps-caption
		@apply mt-5 xl:mt-5.5
	.easy-steps-desc
		@apply mt-7.5 text-lg leading-1.33 text-dark font-normal space-y-4 xl:mt-8.5
		ul
			@apply space-y-4
		li
			@apply pl-9.5 relative
			&::before
				@apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 pointer-events-none

.attractive-cost-box
	@apply bg-gray-f3 rounded-2.5 p-5 max-w-[calc(690/1920*100rem)] mx-auto xl:px-7 xl:pb-7.5
	.attractive-cost-icon
		@apply flex-center mb-5
	.secondary-title
		@apply font-normal
	.btn
		@apply xl:hover:bg-transparent

.service-detail-section
	.img-scale
		@apply pt-[calc(494/690*100%)] rounded-2.5
	.global-desc
		h2
			@apply text-xl font-normal text-primary leading-1.2 mb-4.5 xl:text-26
		ul
			@apply space-y-4
		li
			@apply pl-9.5 relative
			&::before
				@apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 top-0 pointer-events-none

