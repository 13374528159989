.global-breadcrumb
	@apply bg-white border-b border-white
	.rank-math-breadcrumb
		@apply flex flex-wrap items-center py-2
		> p
			@apply flex items-center flex-wrap
		.separator
			@apply text-base leading-normal font-normal text-gray-99
		a, span:not(.separator)
			@apply block text-base leading-normal font-normal text-gray-99 px-4.5 xl:py-0.25 xl:hover:text-primary
			&.active
				@apply text-primary
			&:first-child
				@apply pl-0
			&:last-child
				@apply pr-0
