input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
[multiple],
textarea
	@apply relative w-full bg-white border border-solid border-gray-e1 rounded-2.5 h-12.5 pl-6 pr-12.5 py-1 text-base text-gray-66 font-normal
	@apply focus:z-1
	@apply placeholder:text-gray-66 placeholder-shown:bg-gray-f8
	@apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black
	@apply read-only:bg-gray-e5 read-only:border-gray-cc read-only:text-black

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>")
	@apply relative w-full bg-white border border-solid border-gray-e1 rounded-2.5 h-12.5 pl-6 pr-12.5 py-1 text-base text-gray-66 font-normal
	@apply focus:z-1
	@apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black
	// @apply read-only:bg-gray-e5 read-only:border-gray-cc read-only:text-black

textarea
	@apply h-25 py-3 xl:h-37.5


.btn-submit,
input[type="submit"],
button[type="submit"]
	@apply transition-all duration-200 ease-linear

.form-group
	@apply mb-5 relative space-y-2
	label
		@apply w-full block text-lg font-bold leading-[calc(22/18)] text-gray-33
	> span, > div > span
		@apply text-xs text-secondary italic block mt-0.5 font-primary font-normal

.input-group
	@apply relative
	.input-group-icon
		@apply absolute top-1/2 -translate-y-1/2 right-4.5 z-1 text-xl text-primary leading-none cursor-pointer m-0
	.toggle-password
		&.active
			@apply before:content-['\f06e']

.form-upload
	@apply space-y-0

	input[type='file']
		@apply hidden

	label
		@apply relative w-max bg-gray-f3 border-none h-12 px-6 py-3 rounded-2.5 text-base text-gray-66 font-normal flex-center cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-e5

.form-noted
	@apply text-base font-normal text-gray-30
