.modal
	@apply w-full max-w-[calc(1410/1920*100rem)] p-0 rounded-2.5
	.modal-head
		@apply mb-7.5
	.modal-title
		@apply font-bold text-2xl leading-1.2 text-primary md:text-3xl xl:text-4xl
	.modal-content
		@apply p-7.5 xl:p-15
	.carousel__button
		@media(min-width: 1280px)
			@apply w-11 h-11 bg-primary flex-center rounded-none rounded-tr-2.5
			&.is-close
				@apply top-0 right-0
			svg
				@apply stroke-white

.apply-modal
	@apply max-w-[calc(998/1920*100rem)]
	.modal-content
		@apply xl:px-7.5 xl:pt-12.5 xl:pb-15
	.form-group
		input, textarea
			@apply bg-white text-gray-66
			@apply disabled:bg-gray-f3 disabled:border-gray-f3
			@apply read-only:bg-gray-f3 read-only:border-gray-f3
	.btn-submit
		@apply min-w-[calc(175/1920*100rem)]
