.privacy-policy-box
	@apply xl:sticky xl:top-27 xl:z-10
	.privacy-policy-box-title
		@apply rounded-tl-2.5 rounded-tr-2.5 bg-primary px-7.5 py-3.75 text-white text-lg leading-1.2 capitalize font-medium
	.privacy-policy-box-menu
		@apply rounded-bl-2.5 rounded-br-2.5 bg-gray-f3 px-7.5 py-3.75
		a
			@apply block text-gray-66 text-lg leading-1.2 capitalize font-medium py-4 xl:hover:text-primary xl:hover:bg-gray-f3
		li
			@apply border-b border-gray-e1 last:border-none
			&.active
				a
					@apply text-primary
