.about-1-section
	@apply min-h-[calc(800/1920*100rem)] xl:pt-[calc(55/1920*100rem)]
	.image
		@apply max-w-[calc(532/1920*100rem)] px-7.5 mx-auto
	.global-desc
		strong
			@apply text-primary font-bold
		h2
			@apply text-primary font-light
			strong
				@apply font-extrabold

.about-2-section
	@apply xl:pt-18

.about-2-item
	@apply flex
	.about-2-item-icon
		@apply w-22.5 flex-center px-4 rounded-tr-12.5 rounded-br-12.5 bg-primary min-h-[calc(250/1920*100rem)]
	.about-2-item-caption
		@apply flex-1 pl-7.5
	.about-2-item-title
		@apply global-title text-dark mb-3.25
	.about-2-item-desc
		@apply global-desc text-gray-33


.about-3-section
	.img-scale
		@apply pt-[calc(1205/1920*100%)]
	.global-desc
		@apply leading-1.33
		ul
			@apply pl-0 list-none
	.global-box
		@apply bg-primary/30 p-5 lg:w-max xl:px-7.5 xl:py-15
	.container
		@apply lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:top-0 lg:w-full lg:z-1
