// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none p-0 bg-none
	.line
		@apply absolute block h-[2px] w-full bg-primary rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-primary rounded-0.5 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen xl
		display: none !important

// link a hover underline animation
.hover-underline
	@apply relative transition-all duration-300 bg-no-repeat bg-0-100 hover:bg-200-100

// Global title
.global-title
	@apply text-4xl leading-1.2 font-extrabold xl:text-5xl

.secondary-title
	@apply text-3xl leading-1.2 font-extrabold xl:text-4xl

// Global description
.global-desc
	@apply text-lg leading-1.2 font-normal text-dark space-y-4.5
	h2
		@apply text-26 leading-1.2 mb-6 xl:text-32

// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	&.open
		@apply opacity-100 pointer-events-auto


.post-nav
	@apply mt-5 xl:mt-7.5
	.pager
		@apply flex-center flex-wrap
	a, span
		@apply bg-gray-f3 w-15 h-15 flex-center transition-all duration-200 ease-linear text-xl text-gray-99 font-bold text-center rounded-full
	li
		@apply m-2.5
		&.active, &:hover
			a, span
				@apply text-white bg-primary

// Core - Searchbox
.searchbox
	@apply relative container px-0
	input
		@apply w-full h-12.5 border-gray-99 border rounded-1.25 pl-5 pr-12.5 text-base text-gray-33 placeholder:text-gray-99 focus:outline-none focus:border-primary
	button
		@apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-secondary

.subscribe
	.wpcf7-form-control-wrap
		@apply block
	.subscribefrm
		@apply relative
		input.subscribeemail
			@apply w-full h-12 border-gray-e1 border rounded-none pl-5 pr-12.5 text-sm text-white bg-transparent
			@apply placeholder:text-white
			@apply focus:outline-none focus:ring-0
		button.subscribebutton
			@apply text-0 bg-white absolute top-1/2 -translate-y-1/2 right-1 w-10 h-10 flex-center text-primary leading-none transition-all duration-200 ease-linear xl:hover:bg-primary xl:hover:text-white
			&::before
				@apply font-awesome text-lg font-black content-['\f061']
	.wpcf7-not-valid-tip
		@apply absolute top-full left-0 w-full text-xs text-red font-normal italic
	// span
	// 	@apply font-primary text-sm text-red font-normal italic block absolute top-full mt-0.5 left-0 w-full before:hidden
