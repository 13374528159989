.service-section
	&:nth-child(2n)
		@apply bg-gray-f3/50
		.container > .row
			@apply md:flex-row-reverse
	&:nth-child(3n)
		@apply xl:py-15
	.global-desc
		@apply leading-1.33
	.button
		@apply mt-3.75

.service-block
	& + .service-block
		@apply pt-10 xl:pt-12.5
	.global-desc
		@apply leading-1.33
	.btn, .link
		@apply text-lg space-x-3

.service-sticky
	@apply sticky top-24 left-0 bg-white z-996
	.navbar-nav
		@apply relative
	.lavalamp
		@apply absolute bottom-0 h-0.5 bg-primary z-1 pointer-events-none transition-all duration-500
	.navbar-toggle
		@apply py-2.5 px-5 text-xl font-bold text-dark shadow flex justify-between items-center xl:hidden
		&.active
			i,em
				@apply rotate-180
	.navbar-menu
		@apply relative py-2.5 hidden gap-5 xl:gap-7.5 xl:py-0 xl:justify-start xl:items-center 2xl:gap-10
		&::before
			content: ''
			@apply bg-gray-e1 h-0.5 w-full rounded-full absolute left-0 bottom-0 w-full z-1 pointer-events-none hidden xl:block
		a
			@apply block text-lg font-normal text-gray-66 font-bold leading-none uppercase py-2.5 px-5 xl:pt-0 xl:pb-3.5 xl:px-0
		li
			&.active
				a
					@apply text-primary
		@media(min-width: 1280px)
			display: flex !important

.featured-item
	.featured-icon
		@apply flex-center
	.featured-title
		@apply text-xl leading-1.2 font-semibold text-gray-30 text-center px-5 py-4.5

.featured-form-box
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)
	@apply bg-white rounded-2.5 p-7.5 xl:-mr-7.5 xl:max-w-[calc(630/1920*100rem)] xl:ml-auto
	.featured-form-title
		@apply text-2xl font-bold uppercase leading-1.2 text-dark text-center mb-4.25 xl:text-3xl
	.featured-form-desc
		@apply text-lg leading-normal font-normal text-gray-30 mb-5 xl:text-base
	.row
		@apply -mx-2.5
		> *
			@apply px-2.5
	.btn-submit
		@apply uppercase px-12.5
	.form-group
		input, select, textarea
			@apply bg-white text-base text-gray-66 px-5
			@apply placeholder-shown:bg-white
		textarea
			@apply h-30
	.form-submit
		@apply flex-center

.jurisdictions-item
	@apply h-full
	.img-scale
		@apply pt-[calc(260/450*100%)] rounded-2.5
	.jurisdictions-caption
		@apply py-5 px-4.5 xl:pt-6.5 xl:pb-4
	.jurisdictions-title
		@apply text-xl font-bold leading-1.2 text-black mb-2.5 xl:hover:text-primary
	.jurisdictions-desc
		@apply text-lg leading-1.33 text-dark
	@media(min-width: 1280px)
		&:hover
			.img-scale
				img
					@apply scale-110

.jurisdictions-tabslet
	.tabslet-tabs
		@apply flex items-center overflow-x-auto pb-3.5 mb-7.5
		a
			@apply block whitespace-nowrap text-2xl font-semibold leading-1.2 text-gray-99 relative hover:text-primary
			&::before
				content: ''
				@apply absolute left-0 -bottom-3.5 w-full h-0.5 bg-transparent transition-all duration-200 ease-linear
		li
			@apply w-max pr-10 mr-10 border-r border-gray-99 last:border-none last:mr-0
			&.active
				a
					@apply text-primary before:bg-primary

.works-item
	@apply relative
	&::before
		@apply font-awesome content-['\f178'] font-normal text-primary text-50 absolute top-[4%] right-0 pointer-events-none hidden md:block xl:-right-7.5
	.works-icon
		@apply bg-primary flex-center p-2.5 w-27 h-27 rounded-full mx-auto
	.works-caption
		@apply mt-5 xl:mt-6.5
	.works-title
		@apply text-xl text-dark uppercase text-center uppercase font-bold leading-1.2 leading-none
	.works-desc
		@apply mt-7.5 text-lg leading-1.33 text-dark font-normal space-y-4 text-center xl:mt-8.5
		ul
			@apply space-y-4 text-left
		li
			@apply pl-9.5 relative
			&::before
				@apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 pointer-events-none

.works-list
	> .row
		> *
			&:last-child
				.works-item
					@apply before:hidden

.accordion-list
	@apply space-y-3.75

.accordion-item
	.accordion-header
		@apply bg-gray-f3 text-dark relative rounded-2.5 pl-5 pr-15 py-3.75 cursor-pointer xl:pr-22.5
	.accordion-title
		@apply text-current font-bold text-lg leading-1.33 flex
	.accordion-number
		@apply text-26 font-bold leading-none mr-5 xl:mr-5.5
	.accordion-icon
		@apply absolute z-1 top-1/2 -translate-y-1/2 right-5 text-xl text-gray-99 xl:right-15 xl:cursor-pointer
	.accordion-content
		@apply mt-3.75 bg-gray-f3 rounded-2.5 p-5 text-lg leading-normal text-dark font-normal space-y-4 hidden xl:py-6.25 xl:text-base
		ul
			@apply space-y-4
		li
			@apply pl-9.5 relative
			&::before
				@apply font-awesome content-['\f058'] font-bold text-primary text-xl absolute left-0 top-0.5 pointer-events-none
	&.active
		.accordion-header
			@apply bg-primary text-white
		.accordion-icon
			@apply rotate-180 text-white

.download-form-item
	@apply bg-gray-f3 rounded-2.5 p-5 min-h-[calc(143/1920*100rem)] flex flex-col justify-between xl:py-6 xl:pb-7.5
	.download-form-title
		@apply text-xl font-bold leading-1.2 text-dark mb-5 xl:hover:text-primary
	.download-form-status
		@apply text-lg leading-1.33 text-gray-33 font-normal
		i, em
			@apply mr-3.25
	.download-form-download-file
		@apply text-lg leading-1.33 text-gray-33 font-normal xl:hover:text-primary
		i, em
			@apply mr-3.25

.service-faqs-block
	.accordion-item
		.accordion-header
			@apply pr-10 xl:pr-15
		.accordion-icon
			@apply right-7.5
