
.service-wrap
	@apply px-3.75 md:max-w-[calc(1640/1920*100rem)] md:mx-auto
	.service-wrap-item
		&:nth-child(2n)
			.row
				@apply md:flex-row-reverse

.service-wrap-item
	@apply rounded-7.5 overflow-hidden p-10 xl:px-25
	.service-title
		@apply global-title mb-6 xl:max-w-[calc(520/1920*100rem)]
	.service-desc
		@apply text-lg text-black font-normal leading-1.2 space-y-4 xl:max-w-[calc(600/1920*100rem)]
	.service-button
		@apply mt-5 xl:mt-7.5
		.btn
			@apply xl:hover:bg-transparent
