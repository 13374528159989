.event-news-item
	@apply relative p-2.5 transition-all duration-200 ease-linear rounded-2.5
	.event-news-img
		@apply relative
	.img-scale
		@apply pt-[calc(248.44/430*100%)] rounded-2.5
	.event-news-date
		@apply absolute top-7.5 left-7.5 z-1 rounded-1.25 bg-primary p-1 text-white font-bold text-xs leading-1.2 text-center z-10 xl:w-15 xl:h-15
		.dd
			@apply font-extrabold text-3xl block text-center
		.mm-yyyy
			@apply whitespace-nowrap
	.event-news-caption
		@apply pt-6.25 px-5 pb-7.5 space-y-2.5
	.event-news-title
		@apply text-xl font-bold leading-1.2 text-dark xl:hover:text-primary
	.event-news-desc
		@apply text-lg text-dark font-normal leading-1.2 line-clamp-3 h-[calc(66/1920*100rem)]
	.event-news-button
		@apply mt-3
	@screen xl
		&:hover
			@apply bg-white
			.img-scale
				img
					@apply scale-110

	&.event-news-big-item
		@apply relative p-0 rounded-2.5 overflow-hidden
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 86.25%)
			@apply absolute top-0 left-0 z-1 w-full h-full pointer-events-none
		.img-scale
			@apply pt-[calc(468/810*100%)]
		.event-news-date
			@apply xl:w-22.5 xl:h-22.5 xl:p-2.5 xl:text-sm
			.dd
				@apply xl:text-[calc(43/1920*100rem)]
		.event-news-caption
			@apply absolute left-0 bottom-0 w-full px-7.5 py-6 z-10
		.event-news-title
			@apply text-white
		.event-news-desc
			@apply text-white

	&.event-news-horizontal-item
		@apply flex items-center p-0
		.event-news-img
			@apply w-[calc(240/1920*100rem)]
		.img-scale
			@apply pt-[calc(136/240*100%)]
		.event-news-date
			@apply top-2.5 left-2.5
		.event-news-caption
			@apply flex-1 pl-5 pr-0 py-0
		.event-news-title
			@apply line-clamp-2 normal-case
		.event-news-desc
			@apply line-clamp-2

.navbar-nav-zone
	@apply py-2.5 sticky top-24 left-0 bg-white z-996

.navbar-menu-zone
	@apply relative flex items-center justify-center mx-auto w-max
	&::before
		content: ''
		@apply bg-gray-e1 h-0.5 w-full rounded-full absolute left-0 bottom-0 z-1 pointer-events-none
	a
		@apply relative block text-lg font-normal text-gray-66 font-bold leading-none uppercase py-2.5 px-5 xl:pt-0 xl:pb-3.5 xl:px-0 xl:hover:text-primary
		&::before
			content: ''
			@apply bg-primary h-0.5 w-full rounded-full absolute left-0 bottom-0 z-2 pointer-events-none opacity-0 transition-all duration-200 ease-linear
	li
		@apply px-15 first:pl-0 last:pr-0
		&.active
			a
				@apply text-primary before:opacity-100

.news-list-section
	@media(max-width: 575.89px)
		.navbar-menu-zone
			@apply overflow-x-auto w-full justify-start pb-5
			li
				@apply pl-0 pr-10 last:pr-0
			a
				@apply whitespace-nowrap px-0
